import React, { useRef } from "react"
import cn from "classnames"
import { useLink } from "@react-aria/link"

const AriaLink = (props) => {
  const ref = useRef()
  const { linkProps } = useLink(
    {
      ...props,
      elementType: props?.as === "span" || props?.as === "div" ? props.as : "a",
    },
    ref
  )
  const Component = props?.as === "span" || props?.as === "div" ? props.as : "a"
  return (
    <Component
      {...linkProps}
      ref={ref}
      href={props.href}
      target={props.target}
      className={cn("cursor-pointer", props.className)}
    >
      {props.children}
    </Component>
  )
}

export default AriaLink

import React from "react"
import styled from "styled-components"
import cn from "classnames"

const marginGutterWidthClassNames = {
  1: "-mx-[2.5px]",
}

const UnstyledRow = ({
  className,
  gridGutterWidth = null,
  noGutters = false,
  guttersClassName = null,
  tag: Tag = "div",
  ...props
}) => {
  const gutterWidthClassName = !noGutters
    ? guttersClassName
      ? guttersClassName
      : marginGutterWidthClassNames[gridGutterWidth]
      ? marginGutterWidthClassNames[gridGutterWidth]
      : "-mx-3"
    : ""
  return (
    <Tag
      className={cn("flex flex-wrap", gutterWidthClassName, className)}
      {...props}
    />
  )
}

const Row = styled(UnstyledRow)``

export default Row

import React, { useState } from "react"
import styled from "styled-components"
import cn from "classnames"
import { IconButton } from "~components/Button"
import PlayerVolumeSlider from "./PlayerVolumeSlider"
import { useAccessibility } from "~components/Accessibility/AccessibilityProvider"
import { useTheme } from "~hooks/theme"

const MuteButton = styled(IconButton)`
  cursor: pointer;
  border-radius: 3px;
  border: none;
  padding: 0 0 0 10px;
  color: ${({ theme }) => theme.darkishBlue};
  height: 40px;
  .accessible & {
    color: ${({ theme }) => theme.darkBlueAccessible};
    border-color: ${({ theme }) => theme.darkBlueAccessible};
    &:not(.playing) {
      svg {
        path {
          fill: ${({ theme }) => theme.darkBlueAccessible};
        }
      }
    }
  }
  .white & {
    svg {
      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }
`

const usePlayerVolume = (props) => {
  const theme = useTheme()

  const {
    accessibility: { accessible },
  } = useAccessibility()

  const [volumeState, setVolumeState] = useState({
    volume: 1,
    volumeOnMute: 0,
  })

  const volumeChangeHandler = (value) => {
    const volume = value.shift()
    if (!isNaN(volume)) {
      setVolumeState((prevState) => {
        return {
          ...prevState,
          volume,
        }
      })
    }
  }

  const mute = () => {
    setVolumeState((prevState) => {
      return {
        ...prevState,
        volume: prevState.volume > 0 ? 0 : prevState.volumeOnMute,
        volumeOnMute:
          prevState.volume > 0 ? prevState.volume : prevState.volumeOnMute,
      }
    })
  }

  const playerVolume = (
    <div
      className={cn("flex items-center", {
        accessible: accessible,
        white: props?.color === "white",
      })}
    >
      <MuteButton
        className="flex justify-end mr-2"
        onPress={() => mute()}
        aria-controls="radiostream-player-audio"
        aria-label={
          volumeState.volume > 0
            ? "Désactiver le son (m)"
            : "Activer le son (m)"
        }
      >
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
        >
          <path
            fill={theme.darkishBlue}
            opacity={volumeState.volume > 0 ? 1 : 0.3}
            d="M18.28 8.37a1 1 0 1 0-1.56 1.26 4 4 0 0 1 0 4.74A1 1 0 0 0 17.5 16a1 1 0 0 0 .78-.37 6 6 0 0 0 0-7.26z"
          />

          <path
            fill={theme.darkishBlue}
            opacity={volumeState.volume >= 0.5 ? 1 : 0.3}
            d="M19.64 5.23a1 1 0 1 0-1.28 1.54A6.8 6.8 0 0 1 21 12a6.8 6.8 0 0 1-2.64 5.23 1 1 0 0 0-.13 1.41A1 1 0 0 0 19 19a1 1 0 0 0 .64-.23A8.75 8.75 0 0 0 23 12a8.75 8.75 0 0 0-3.36-6.77z"
          />
          <path
            fill={theme.darkishBlue}
            d="M14.47 3.12a1 1 0 0 0-1 0L7 7.57H2a1 1 0 0 0-1 1v6.86a1 1 0 0 0 1 1h5l6.41 4.4A1.06 1.06 0 0 0 14 21a1 1 0 0 0 1-1V4a1 1 0 0 0-.53-.88z"
          />
        </svg>
      </MuteButton>
      <PlayerVolumeSlider
        aria-label={
          volumeState.volume > 0
            ? `Volume activé à ${parseInt(volumeState.volume * 100)}%`
            : "Volume désactivé"
        }
        aria-controls="radiostream-player-audio"
        formatOptions={{ style: "percent" }}
        minValue={0}
        maxValue={1}
        step={0.01}
        value={[volumeState.volume]}
        onChange={volumeChangeHandler}
        color={props?.color}
      />
    </div>
  )

  return {
    playerVolume,
    volume: volumeState.volume,
    mute,
    volumeState,
    setVolumeState,
  }
}

export default usePlayerVolume

import { css } from "styled-components"
import { upMd } from "~utils/breakpoints"
import Color from "color"

// Define mixins

export const ButtonStyle = css`
  &.btn {
    outline: none;
    letter-spacing: 0.5px;
    display: inline-block;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 30px;
    text-align: center;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    transition: all 0.2s ease-in-out;
    &:focus {
      box-shadow: none;
    }
  }

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg + span,
    span + svg {
      margin-right: 0;
      margin-left: 10px;
    }
    svg {
      flex-shrink: 0;
    }
  }

  &.icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: 0;
    appearance: none;
    &:hover,
    &:focus {
      background: transparent;
    }
  }

  &.btn-primary {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.primary};
    border: solid 1px ${({ theme }) => theme.primary};
    &:active,
    &.active,
    &:hover {
      background-color: ${({ theme }) =>
        Color(theme.primary).darken(0.2).toString()};
      border-color: ${({ theme }) =>
        Color(theme.primary).darken(0.12).toString()};
    }
  }

  &.btn-white {
    color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.white};
    &:active,
    &.active,
    &:hover {
      background-color: ${({ theme }) =>
        Color(theme.white).darken(0.2).toString()};
      border-color: ${({ theme }) =>
        Color(theme.white).darken(0.12).toString()};
    }
  }

  &.btn-transparent-outline-white {
    color: ${({ theme }) => theme.white};
    background: transparent;
    border: solid 1px ${({ theme }) => theme.white};
    &:active,
    &.active,
    &:hover {
      border-color: ${({ theme }) =>
        Color(theme.white).darken(0.12).toString()};
    }
  }

  &.btn-transparent-outline-black {
    color: ${({ theme }) => theme.black};
    background: transparent;
    border: solid 1px ${({ theme }) => theme.black};
    &:active,
    &.active,
    &:hover {
      border-color: ${({ theme }) => Color(theme.white).darken(0.6).toString()};
    }
  }

  &.btn-transparent-outline-darkish-blue {
    color: ${({ theme }) => theme.darkishBlue};
    background: transparent;
    border: solid 1px ${({ theme }) => theme.darkishBlue};
    &:active,
    &.active,
    &:hover {
      border-color: ${({ theme }) =>
        Color(theme.white).darken(0.12).toString()};
    }
  }
`

export const TextStyle = css`
  font-size: 1rem;
  letter-spacing: -0.26px;
  ${upMd`letter-spacing: normal;`}
  .btn {
    ${ButtonStyle}
  }
  &.lead {
    color: ${({ theme }) => theme.black};
    font-size: 25px;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: -0.4px;
    ${upMd`
      font-size: 32px;
      line-height: normal;
      letter-spacing: -0.56px;
  `}
  }

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  a {
    color: ${({ theme }) => theme.black};
    text-decoration: underline;
  }
  a:hover {
    color: ${({ theme }) => theme.black};
    text-decoration: underline;
  }
  &.text-white a {
    color: ${({ theme }) => theme.white};
  }
  &.text-white a:hover {
    color: ${({ theme }) => theme.white};
  }
  &.text-white p {
    color: ${({ theme }) => theme.white};
  }
  ul {
    padding-left: 20px;
  }
  li {
    list-style: disc;
  }
  h2 {
    font-family: "Vinci Sans";
    font-size: 35px;
    color: ${({ theme }) => theme.black};
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 30px;
  }
  h2:not(:first-child) {
    margin-top: 30px;
  }
  h3 {
    font-family: "Vinci Sans";
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: ${({ theme }) => theme.black};
    margin-bottom: 30px;
  }
  h3:not(:first-child) {
    margin-top: 30px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table td,
  table th {
    padding: 10px;
  }
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  table th {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    font-weight: 500;
    background-color: ${({ theme }) => theme.darkishBlue};
    color: ${({ theme }) => theme.white};
  }
`

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import LayoutWrapper from "./src/components/Layout/LayoutWrapper"
// Import the taiwind CSS file
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <LayoutWrapper {...props}>{element}</LayoutWrapper>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if (!prevRouterProps) {
    return true
  }

  if (location.pathname === prevRouterProps.location.pathname) {
    return false
  }
}

// Define the variables used by tailwind and ThemeProvider

module.exports = {
  spacing: {
    px: "1px",
    0: "0px",
    1: "5px",
    2: "10px",
    3: "15px",
    4: "20px",
    5: "25px",
    6: "30px",
    7: "35px",
    8: "40px",
    9: "45px",
    10: "50px",
    11: "55px",
    12: "60px",
    13: "65px",
    14: "70px",
    15: "75px",
    16: "80px",
    17: "85px",
    18: "90px",
    19: "95px",
    20: "100px",
    21: "105px",
    22: "110px",
    23: "115px",
    24: "120px",
  },
  // re uses the Boostrap 4 breakpoints definitions
  screens: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "1440px",
  },
  container: {
    center: true,
    padding: "15px",
    screens: {
      sm: "540px",
      md: "720px",
      lg: "960px",
      xl: "1200px",
    },
  },
  colors: {
    transparent: "transparent",
    alto: "#dbdbdb",
    yellow: "#fff14b",
    greenLight: "#a0efc2",
    blueLight: "#65c1f7",
    darkishBlue: "#003c7e",
    pinkLight: "#faf7f5",
    grey: "#727272",
    blueFooter: "#e9f2fa",
    green: "#5cb85c",
    greyInput: "#a8a8a8",
    blueQuote: "#28323c",
    white: "#ffffff",
    black: "#000000",
    lightGreyBlue: "#A1ADBE",
    lightGrey: "#EEF2F7",
    "gray-100": "#EEF2F7",
    "gray-200": "#A1ADBE",
    "gray-600": "#4F5F7A",
    primary: "#2A508F",
    secondary: "#2C9FE2",
    zircon: "#f8f9ff",
    education: "#65c1f7",
    environnement: "#a0efc2",
    securite_routiere: "#fff14b",
    biodiversite: "#EADBC7",
    chercheurs: "#ACACAC",
    starkWhite: "#EADBC7",
    silverChalice: "#ACACAC",
    tan: "#D5B790",
    red: "#E20025",
  },
  backgroundColor: {},
  backgroundImage: {
    persianBlueGradient:
      "linear-gradient(0deg, rgba(30,25,127,1) 0%, rgba(42,38,182,1) 50%, rgba(51,46,220,1) 100%)",
  },
  borderWidth: {
    DEFAULT: "1px",
    0: "0",
    2: "2px",
    3: "3px",
    4: "4px",
    5: "5px",
    6: "6px",
    8: "8px",
    10: "10px",
  },
}

import React, { useState } from "react"
import styled from "styled-components"
import cn from "classnames"
import Duration from "~components/Duration"
import PlayerProgressBarSlider from "./PlayerProgressBarSlider"
import { useAccessibility } from "~components/Accessibility/AccessibilityProvider"

const ProgressBarPlaceholder = styled.div``

const usePlayerProgressBar = (props) => {
  const {
    accessibility: { accessible },
  } = useAccessibility()

  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentBuffer, setCurrentBuffer] = useState(null)

  const handleChange = (event) => {
    if (event) {
      const position = event.shift()
      if (!isNaN(position) && position !== Infinity) {
        setCurrentTime(position)
        if (props.onChange) {
          props.onChange(position)
        }
      }
    }
  }

  const playerProgressBar = (
    <ProgressBarPlaceholder
      className={cn("flex items-center w-full", {
        accessible: accessible,
      })}
    >
      <Duration
        seconds={loading ? 0 : currentTime}
        className={cn("mr-3", { "text-white": props?.color === "white" })}
      />
      <PlayerProgressBarSlider
        minValue={0}
        maxValue={duration}
        value={[currentTime]}
        onChange={handleChange}
        isDisabled={!duration || duration === Infinity}
        label="Barre de progression"
        loading={loading}
        loadingValue={
          duration && duration !== Infinity && currentBuffer
            ? currentBuffer / duration
            : null
        }
        aria-controls="radiostream-player-audio"
        color={props?.color}
      />
      {duration && duration !== Infinity && (
        <Duration
          seconds={duration}
          className={cn("ml-3", { "text-white": props?.color === "white" })}
        />
      )}
    </ProgressBarPlaceholder>
  )

  return {
    playerProgressBar,
    currentTime,
    duration,
    loading,
    currentBuffer,
    setCurrentTime,
    setDuration,
    setLoading,
    setCurrentBuffer,
  }
}

export default usePlayerProgressBar

import React from "react"
import cn from "classnames"

const paddingGutterWidthClassNames = {
  1: "px-[2.5px]",
}

const widths = {
  xs: [
    "max-w-[calc(1/12*100%)] basis-1/12",
    "max-w-[calc(2/12*100%)] basis-2/12",
    "max-w-[calc(3/12*100%)] basis-3/12",
    "max-w-[calc(4/12*100%)] basis-4/12",
    "max-w-[calc(5/12*100%)] basis-5/12",
    "max-w-[50%] basis-1/2",
    "max-w-[calc(7/12*100%)] basis-7/12",
    "max-w-[calc(8/12*100%)] basis-8/12",
    "max-w-[calc(9/12*100%)] basis-9/12",
    "max-w-[calc(10/12*100%)] basis-10/12",
    "max-w-[calc(11/12*100%)] basis-11/12",
    "max-w-full basis-full",
  ],
  sm: [
    "sm:max-w-[calc(1/12*100%)] sm:basis-1/12",
    "sm:max-w-[calc(2/12*100%)] sm:basis-2/12",
    "sm:max-w-[calc(3/12*100%)] sm:basis-3/12",
    "sm:max-w-[calc(4/12*100%)] sm:basis-4/12",
    "sm:max-w-[calc(5/12*100%)] sm:basis-5/12",
    "sm:max-w-[50%] sm:basis-1/2",
    "sm:max-w-[calc(7/12*100%)] sm:basis-7/12",
    "sm:max-w-[calc(8/12*100%)] sm:basis-8/12",
    "sm:max-w-[calc(9/12*100%)] sm:basis-9/12",
    "sm:max-w-[calc(10/12*100%)] sm:basis-10/12",
    "sm:max-w-[calc(11/12*100%)] sm:basis-11/12",
    "sm:max-w-full sm:basis-full",
  ],
  md: [
    "md:max-w-[calc(1/12*100%)] md:basis-1/12",
    "md:max-w-[calc(2/12*100%)] md:basis-2/12",
    "md:max-w-[calc(3/12*100%)] md:basis-3/12",
    "md:max-w-[calc(4/12*100%)] md:basis-4/12",
    "md:max-w-[calc(5/12*100%)] md:basis-5/12",
    "md:max-w-[50%] md:basis-1/2",
    "md:max-w-[calc(7/12*100%)] md:basis-7/12",
    "md:max-w-[calc(8/12*100%)] md:basis-8/12",
    "md:max-w-[calc(9/12*100%)] md:basis-9/12",
    "md:max-w-[calc(10/12*100%)] md:basis-10/12",
    "md:max-w-[calc(11/12*100%)] md:basis-11/12",
    "md:max-w-full md:basis-full",
  ],
  lg: [
    "lg:max-w-[calc(1/12*100%)] lg:basis-1/12",
    "lg:max-w-[calc(2/12*100%)] lg:basis-2/12",
    "lg:max-w-[calc(3/12*100%)] lg:basis-3/12",
    "lg:max-w-[calc(4/12*100%)] lg:basis-4/12",
    "lg:max-w-[calc(5/12*100%)] lg:basis-5/12",
    "lg:max-w-[50%] lg:basis-1/2",
    "lg:max-w-[calc(7/12*100%)] lg:basis-7/12",
    "lg:max-w-[calc(8/12*100%)] lg:basis-8/12",
    "lg:max-w-[calc(9/12*100%)] lg:basis-9/12",
    "lg:max-w-[calc(10/12*100%)] lg:basis-10/12",
    "lg:max-w-[calc(11/12*100%)] lg:basis-11/12",
    "lg:max-w-full lg:basis-full",
  ],
  xl: [
    "xl:max-w-[calc(1/12*100%)] xl:basis-1/12",
    "xl:max-w-[calc(2/12*100%)] xl:basis-2/12",
    "xl:max-w-[calc(3/12*100%)] xl:basis-3/12",
    "xl:max-w-[calc(4/12*100%)] xl:basis-4/12",
    "xl:max-w-[calc(5/12*100%)] xl:basis-5/12",
    "xl:max-w-[50%] xl:basis-1/2",
    "xl:max-w-[calc(7/12*100%)] xl:basis-7/12",
    "xl:max-w-[calc(8/12*100%)] xl:basis-8/12",
    "xl:max-w-[calc(9/12*100%)] xl:basis-9/12",
    "xl:max-w-[calc(10/12*100%)] xl:basis-10/12",
    "xl:max-w-[calc(11/12*100%)] xl:basis-11/12",
    "xl:max-w-full xl:basis-full",
  ],
}

const Col = ({
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  gridGutterWidth = null,
  guttersClassName = null,
  noGutters = false,
  tag: Tag = "div",
  ...props
}) => {
  const breakpointClassName = []
  const breakpointProps = { xs, sm, md, lg, xl }
  Object.keys(breakpointProps).forEach((bp) => {
    if (breakpointProps[bp]) {
      breakpointClassName.push(widths[bp][parseInt(breakpointProps[bp]) - 1])
    }
  })
  const gutterWidthClassName = !noGutters
    ? guttersClassName
      ? guttersClassName
      : paddingGutterWidthClassNames[gridGutterWidth]
      ? paddingGutterWidthClassNames[gridGutterWidth]
      : "px-3"
    : ""
  return (
    <Tag
      className={cn(
        "w-full shrink-0 min-h-px",
        gutterWidthClassName,
        breakpointClassName,
        className
      )}
      {...props}
    />
  )
}

export default Col

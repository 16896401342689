import React from "react"
import styled from "styled-components"
import cn from "classnames"
import { A } from "~components/A"
import { AriaButton } from "~components/Button"

const UnstyledButton = ({
  className,
  color = "primary",
  outline = false,
  ...props
}) => {
  const Component = props.href || props.to ? A : AriaButton
  return (
    <Component
      className={cn(
        "btn",
        color ? `btn-${outline ? "outline-" : ""}${color}` : null,
        className
      )}
      {...props}
    />
  )
}

const Button = styled(UnstyledButton)``

export default Button

import React from "react"
import styled from "styled-components"
import cn from "classnames"
import { Link } from "gatsby"
import path from "path"
import { AriaLink } from "~components/A"

export const UnstyledA = (props) => {
  const {
    href,
    to,
    target,
    rel,
    disabled,
    color,
    className,
    onClick,
    tag: Tag = "span",
    ...attributes
  } = props

  let relAttr = []
  if (rel) {
    relAttr = rel.split(" ")
  }

  const link = href || to

  const classNameProp = color ? cn(className, `btn btn-${color}`) : className

  // Use Gatsby Link for internal links
  if (/^\/(?!\/)/.test(link)) {
    let internalLink = link
    if (!path.extname(internalLink)) {
      internalLink = link.replace(/\/?(\?|#|$)/, "/$1") // adds the trailing slash of internal links
    }
    return (
      <Link
        to={internalLink}
        className={classNameProp}
        onClick={onClick}
        {...attributes}
      />
    )
  } else {
    relAttr = [...relAttr, "noreferrer", "nofollow"]
  }

  if (!link) {
    return <Tag className={classNameProp} {...attributes} />
  }

  const targetValue = target ? target : link ? "_blank" : null

  return (
    <AriaLink
      as={Tag}
      rel={
        relAttr.length > 0
          ? relAttr
              .filter(function (value, index, self) {
                return self.indexOf(value) === index
              })
              .join(" ")
          : null
      }
      className={classNameProp}
      isDisabled={disabled}
      onPress={() => {
        window.open(link, targetValue)
        if (onClick) {
          onClick()
        }
      }}
      aria-label={
        targetValue === "_blank"
          ? typeof props.children === "string"
            ? `${props.children} (nouvelle fenêtre)`
            : "Ouvrir dans une nouvelle fenêtre"
          : null
      }
      {...attributes}
    />
  )
}

const A = styled(UnstyledA)`
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  &.stretched-link {
    color: inherit;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
`

export default A

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-category-template-js": () => import("./../../../src/templates/ArticleCategoryTemplate.js" /* webpackChunkName: "component---src-templates-article-category-template-js" */),
  "component---src-templates-article-press-template-js": () => import("./../../../src/templates/ArticlePressTemplate.js" /* webpackChunkName: "component---src-templates-article-press-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-funding-request-template-js": () => import("./../../../src/templates/FundingRequestTemplate.js" /* webpackChunkName: "component---src-templates-funding-request-template-js" */),
  "component---src-templates-governance-template-js": () => import("./../../../src/templates/GovernanceTemplate.js" /* webpackChunkName: "component---src-templates-governance-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/IndexTemplate.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-media-room-template-js": () => import("./../../../src/templates/MediaRoomTemplate.js" /* webpackChunkName: "component---src-templates-media-room-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-partners-template-js": () => import("./../../../src/templates/PartnersTemplate.js" /* webpackChunkName: "component---src-templates-partners-template-js" */),
  "component---src-templates-podcast-template-js": () => import("./../../../src/templates/PodcastTemplate.js" /* webpackChunkName: "component---src-templates-podcast-template-js" */),
  "component---src-templates-seo-pagination-template-js": () => import("./../../../src/templates/SeoPaginationTemplate.js" /* webpackChunkName: "component---src-templates-seo-pagination-template-js" */)
}


import React, { useRef, useCallback, createContext, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { lunr } from "~utils/search"

const highlight = (doc, match, config) => {
  const newDoc = {}

  const positions = Object.values(match.metadata).reduce(
    (accu, tokenMetadata) => {
      Object.entries(tokenMetadata).forEach(([field, metadata]) => {
        if (!Object.prototype.hasOwnProperty.call(config, field)) {
          return
        }
        if (!config[field]) {
          return
        }
        if (!Object.prototype.hasOwnProperty.call(accu, field)) {
          accu[field] = []
        }
        accu[field] = accu[field].concat(metadata.position)
      })
      return accu
    },
    {}
  )

  Object.entries(positions).forEach(([field, values]) => {
    if (doc[field]) {
      let prevPos = 0
      const chunks = []

      // Ugly code to get unique array of arrays...
      const uniqueStringArray = new Set(values.map(JSON.stringify))
      const uniqueValues = Array.from(uniqueStringArray, JSON.parse)

      uniqueValues.sort((a, b) => a[0] - b[0])

      uniqueValues.forEach(position => {
        const [start, length] = position
        if (prevPos < start) {
          chunks.push(doc[field].substr(prevPos, start - prevPos))
        }
        chunks.push(`<mark>${doc[field].substr(start, length)}</mark>`)
        prevPos = start + length
      })
      if (prevPos < doc[field].length) {
        chunks.push(doc[field].substr(prevPos))
      }
      newDoc[field] = chunks.join("")
    }
  })

  return { ...doc, ...newDoc }
}

const SearchIndexContext = createContext()

export const SearchIndexProvider = ({ defaultIndex, ...props }) => {
  const { indices: rawIndices } = useStaticQuery(
    graphql`
      query {
        indices: allSearchIndex {
          nodes {
            key
            raw
          }
        }
      }
    `
  )

  const indices = useRef()

  if (!indices.current) {
    indices.current = rawIndices.nodes.reduce((newIndices, node) => {
      const data = JSON.parse(node.raw)
      const index = lunr.Index.load(data.index)
      index.documentStore = data.documentStore
      newIndices[node.key] = index
      return newIndices
    }, {})
  }

  const search = useCallback((term, options = {}) => {
    const {
      index: indexId = defaultIndex,
      highlight: highlightConfig = {},
    } = options

    if (!Object.prototype.hasOwnProperty.call(indices.current, indexId)) {
      return []
    }

    const index = indices.current[indexId]

    const tokens = term
      .split(/[\s\-'’,]+/)
      .filter(token => token)
      .sort((a, b) => b.length - a.length)

    if (!tokens.length) {
      return []
    }

    // Add wildcard to token that has at least 2 characters.
    const wildcardedTokens = [...tokens]
      .filter(token => token.length > 1)
      .map(token => token + "*")

    // Boost the longest token.
    const boostedTokens = [`${tokens[0]}^10`]

    const searchTerms = [
      ...boostedTokens,
      ...tokens,
      ...wildcardedTokens
    ]

    return index
      .search(searchTerms.join(" "))
      .filter(match => {
        return match.score !== 0
      })
      .map(match => {
        const { ref, matchData } = match
        const doc = index.documentStore[ref]
        return highlight(doc, matchData, highlightConfig)
      })
  }, [defaultIndex, indices])

  const rawSearch = useCallback((term, options = {}) => {
    const {
      index: indexId = defaultIndex,
      highlight: highlightConfig = {},
    } = options

    if (!Object.prototype.hasOwnProperty.call(indices.current, indexId)) {
      return []
    }

    const index = indices.current[indexId]

    return index
      .search(term)
      .map(match => {
        const { ref, matchData } = match
        const doc = index.documentStore[ref]
        return highlight(doc, matchData, highlightConfig)
      })
  }, [indices, defaultIndex])

  return (
    <SearchIndexContext.Provider
      value={{ search, rawSearch }}
      {...props}
    />
  )
}

export const useSearchIndex = () => useContext(SearchIndexContext)

import React, { useRef } from "react"
import styled, { keyframes } from "styled-components"
import cn from "classnames"
import { durationFormat } from "~components/Duration"
import { useAccessibility } from "~components/Accessibility/AccessibilityProvider"
import { useSlider, useSliderThumb } from "@react-aria/slider"
import { useSliderState } from "@react-stately/slider"
import { useFocusRing } from "@react-aria/focus"
import { VisuallyHidden } from "@react-aria/visually-hidden"
import { mergeProps } from "@react-aria/utils"
import { useNumberFormatter } from "@react-aria/i18n"

const ThumbWrapper = styled.div`
  width: 11px;
  height: 11px;
  top: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
  ${({ theme, isFocusVisible }) =>
    isFocusVisible &&
    `
    &:before {
      position: absolute;
      content: "";
      top: -3px;
      left: -3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: solid 2px ${theme.darkishBlue};
    }
    .accessible &:before {
      border-color: ${theme.darkBlueAccessible};
    }
    .white &:before {
      border-color: ${theme.white};
    }
  `}
`

const ProgressBarSliderWrapper = styled.div`
  touch-action: none;
  &.disabled:not(.loading) {
    opacity: 0.3;
  }
  &:not(.disabled):not(.loading) {
    cursor: pointer;
  }
`

const BarWrapper = styled.div`
  height: 30px;
`
const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const Loader = styled.div`
  height: 3px;
  top: 13px;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-color: ${({ theme }) => theme.darkishBlue};
    animation: ${loading} 0.8s infinite;
  }
  .accessible &:after {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white &:after {
    background-color: ${({ theme }) => theme.white};
  }
`

const Placeholder = styled.div`
  height: 3px;
  top: 13px;
  opacity: 0.3;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
`

const Bar = styled.div`
  height: 3px;
  top: 13px;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
`

const LoadingBar = styled.div`
  height: 3px;
  top: 13px;
  background-color: ${({ theme }) => theme.darkishBlue};
  opacity: 0.5;
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
`

const Thumb = ({ className, onBlur, ...props }) => {
  const { state, trackRef, index } = props
  const inputRef = useRef(null)
  const { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  )

  const { focusProps, isFocusVisible } = useFocusRing()

  const customInputProps = {
    ...mergeProps(
      inputProps,
      focusProps,
      { onBlur },
      {
        "aria-valuetext":
          durationFormat(state.getThumbValue(index), "humanized") +
          (state.getThumbMaxValue(index) &&
          state.getThumbMaxValue(index) !== Infinity
            ? " sur " +
              durationFormat(state.getThumbMaxValue(index), "humanized")
            : ""),
      }
    ),
  }

  return (
    <ThumbWrapper
      {...thumbProps}
      className={cn("relative", className)}
      isFocusVisible={isFocusVisible}
    >
      <VisuallyHidden>
        <input ref={inputRef} {...customInputProps} />
      </VisuallyHidden>
    </ThumbWrapper>
  )
}

const PlayerProgressBarSlider = ({
  className,
  onBlur,
  isDisabled,
  loading,
  loadingValue,
  color,
  ...props
}) => {
  const trackRef = useRef(null)
  const {
    accessibility: { accessible },
  } = useAccessibility()
  const numberFormatter = useNumberFormatter(props.formatOptions)
  const state = useSliderState({ isDisabled, ...props, numberFormatter })
  const { groupProps, trackProps } = useSlider(
    { isDisabled, ...props },
    state,
    trackRef
  )

  return (
    <ProgressBarSliderWrapper
      {...groupProps}
      className={cn(
        "relative w-full",
        { accessible: accessible },
        { disabled: isDisabled },
        { loading: loading },
        { white: color === "white" },
        className
      )}
      aria-label={props.label}
    >
      <BarWrapper {...trackProps} ref={trackRef} className="relative w-full">
        {loading && (
          <Loader
            className="absolute w-full"
            aria-label="Chargement du média en cours..."
            role="progressbar"
          />
        )}
        <Placeholder className="absolute w-full" />
        {loadingValue !== null && (
          <LoadingBar
            className="absolute"
            aria-label={`Barre de chargement du média`}
            aria-valuenow={loadingValue * 100}
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuetext={`Chargement à ${loadingValue * 100}%`}
            role="progressbar"
            style={{
              width: `${loadingValue * 100}%`,
            }}
          />
        )}
        {!loading && (
          <>
            <Bar
              className="absolute"
              style={{
                width: `${state.getThumbPercent(0) * 100}%`,
              }}
            />
            <Thumb
              index={0}
              state={state}
              trackRef={trackRef}
              onBlur={onBlur}
            />
          </>
        )}
      </BarWrapper>
    </ProgressBarSliderWrapper>
  )
}

export default PlayerProgressBarSlider

const lunr = require("lunr")
require("lunr-languages/lunr.stemmer.support")(lunr)
require("lunr-languages/lunr.fr")(lunr)
const asciiFolder = require("fold-to-ascii")

lunr.tokenizer.separator = /[\s\-'’,]+/

// Define custom trimmer
const trimmer = lunr.trimmerSupport.generateTrimmer(
  '0-9' + lunr.fr.wordCharacters
)
lunr.Pipeline.registerFunction(trimmer, "va-trimmer")

// Define custom text processing
function asciiFilter(token) {
  return token.update(function (s) {
    return asciiFolder.foldMaintaining(s)
  })
}
lunr.Pipeline.registerFunction(asciiFilter, "ascii-filter")

function vaPipeline() {
  // Reset default pipelines.
  this.pipeline.reset()
  this.searchPipeline.reset()
  // The pipeline performs text processing on tokens before indexing
  this.pipeline.add(
    trimmer,
    lunr.fr.stopWordFilter,
    asciiFilter,
    lunr.fr.stemmer,
  )
  // The pipeline for processing search terms before querying the index
  this.searchPipeline.add(
    trimmer,
    lunr.fr.stopWordFilter,
    asciiFilter,
    lunr.fr.stemmer,
  )
}

const createLunrIndex = userConfig => {
  // An array of documents kyed by document ID.
  const documentStore = {}
  // An array of documents.
  const docs = []

  const defaultConfig = { ref: "id", fields: {}, plugins: [], metadata: [] }

  const config = { ...defaultConfig, ...userConfig }

  return {

    addDoc: function (doc) {
      documentStore[doc.id] = doc
      docs.push(doc)
    },

    toJSON: function () {
      const index = lunr(function() {
        const builder = this

        builder.ref(config.ref)

        Object.keys(config.fields).forEach(field => {
          builder.field(field, config.fields[field])
        })

        if (config.plugins.length === 0) {
          builder.use(vaPipeline)
        }

        config.plugins.forEach(plugin => {
          builder.use(plugin)
        })

        builder.metadataWhitelist = config.metadata

        docs.forEach(function(doc) {
          builder.add(doc)
        }, builder)
      })

      return { index, documentStore }

    }
  }
}

const createIndex = createLunrIndex

module.exports = { lunr, createIndex }

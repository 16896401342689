import { css } from "styled-components"
import { theme } from "~theme"

// import { upLg } from "~utils/breakpoints"
// const Div = styled.div`
// ${upLg`
//   background: ${({ theme }) => theme.blue};
// `}
// `

const mediaBreakpointUp = (size) => (styles) =>
  css`
    @media (min-width: ${size}) {
      ${styles}
    }
  `

const mediaBreakpointDown = (size) => (styles) =>
  css`
    @media (max-width: ${size}) {
      ${styles}
    }
  `

const mediaBreakpointBetween = (min, max) => (styles) =>
  css`
    @media (min-width: ${min}) and (max-width: ${max}) {
      ${styles}
    }
  `

export const upXs = (...args) => css(...args)

export const upSm = (...args) => mediaBreakpointUp(theme.sm)(css(...args))

export const upMd = (...args) => mediaBreakpointUp(theme.md)(css(...args))

export const upLg = (...args) => mediaBreakpointUp(theme.lg)(css(...args))

export const upXl = (...args) => mediaBreakpointUp(theme.xl)(css(...args))

export const up2Xl = (...args) => mediaBreakpointUp(theme["2xl"])(css(...args))

export const downXs = (...args) =>
  mediaBreakpointDown(parseInt(theme.xs) - 1 + "px")(css(...args))

export const downSm = (...args) =>
  mediaBreakpointDown(parseInt(theme.sm) - 1 + "px")(css(...args))

export const downMd = (...args) =>
  mediaBreakpointDown(parseInt(theme.md) - 1 + "px")(css(...args))

export const downLg = (...args) =>
  mediaBreakpointDown(parseInt(theme.lg) - 1 + "px")(css(...args))

export const onlyLg = (...args) =>
  mediaBreakpointBetween(theme.lg, parseInt(theme.xl) - 1 + "px")(css(...args))

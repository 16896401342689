import React, { createContext, useState, useContext } from "react"

const defaultState = {
  player: {
    audio: {
      open: false,
      title: "",
      teaserColorEffectHover: "",
      image: null,
      playlist: [],
      indexTrack: 0,
      playing: false,
      error: false,
    },
    video: {
      id: "",
      playing: false,
      muted: true,
    },
  },
  setPlayer: () => {},
}

const MediaPlayerContext = createContext(defaultState)

const MediaPlayerProvider = ({ children, ...props }) => {
  const [player, setPlayer] = useState({
    audio: {
      open: false,
      title: "",
      teaserColorEffectHover: "",
      image: null,
      playlist: [],
      indexTrack: 0,
      playing: false,
      error: false,
    },
    video: {
      id: "",
      playing: false,
      muted: true,
    },
  })
  return (
    <MediaPlayerContext.Provider
      value={{
        player,
        setPlayer: setPlayer,
      }}
      {...props}
    >
      {children}
    </MediaPlayerContext.Provider>
  )
}

export default MediaPlayerProvider

export const useMediaPlayer = () => useContext(MediaPlayerContext)

import { useEffect } from "react"
import { useMediaPlayer } from "~components/Player/MediaPlayerProvider"

const usePodcastPlayerShortcuts = ({
  play,
  prev,
  next,
  rw,
  ff,
  mute,
  fullscreen,
}) => {
  const {
    player: {
      audio: { open, playlist },
    },
    setPlayer,
  } = useMediaPlayer()

  useEffect(() => {
    const handleKeyPress = event => {
      if (play && event.key === "k" /*|| event.code === "Space"*/) {
        play()
      }
      if (prev && event.key === "P") {
        prev()
      }
      if (next && event.key === "N") {
        next()
      }
      if (rw && event.key === "j") {
        rw()
      }
      if (ff && event.key === "l") {
        ff()
      }
      if (mute && event.key === "m") {
        mute()
      }
      if (fullscreen && event.key === "f") {
        fullscreen()
      }
    }
    if (open && playlist?.length > 0) {
      document.addEventListener("keypress", handleKeyPress)
    }

    return () => {
      document.removeEventListener("keypress", handleKeyPress)
    }
  }, [ff, next, open, play, playlist, prev, rw, mute, setPlayer, fullscreen])
}

export default usePodcastPlayerShortcuts

import React from "react"

const pad = (string) => {
  return ("0" + string).slice(-2)
}

export const durationFormat = (seconds, format) => {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds()
  if (format === "humanized") {
    let d = ""
    if (hh) {
      d = d + `${hh}h`
    }
    if (mm) {
      d = d + (d.length > 0 ? " " + pad(mm) : mm) + "min"
    }
    if (ss) {
      d = d + (d.length > 0 ? " " + pad(ss) : ss) + "sec"
    }
    return d
  } else {
    if (hh) {
      return `${hh}:${pad(mm)}:${pad(ss)}`
    }
    return `${mm}:${pad(ss)}`
  }
}

export default function Duration({ className, seconds, format }) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {durationFormat(seconds, format)}
    </time>
  )
}

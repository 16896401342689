import styled from "styled-components"
import AriaButton from "./AriaButton"

const IconButton = styled(AriaButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  /*border: none;
  padding: 0;*/
  background-color: transparent;
`

export default IconButton

import React, { useContext, useState } from "react"
import { DidomiSDK } from "@didomi/react"

const DidomiContext = React.createContext({
  status: false,
  vendorsConsent: {
    twitter: null,
    vimeo: null,
  },
  consentTwitter: () => {},
})

export const DidomiProvider = ({ children, ...props }) => {
  const [state, setState] = useState({
    status: false,
    vendorsConsent: {
      twitter: null,
      vimeo: null,
    },
  })

  const handleConsentChanged = () => {
    if (window && window.Didomi) {
      setState((prevState) => {
        return {
          ...prevState,
          status: true,
          vendorsConsent: {
            ...prevState.vendorsConsent,
            twitter: window.Didomi.getUserConsentStatusForVendor("twitter"),
          },
        }
      })
    }
  }

  return (
    <DidomiContext.Provider
      value={{
        ...state,
        consentTwitter: () => {
          if (window && window.Didomi) {
            const transaction = window.Didomi.openTransaction()
            transaction.enablePurpose("cookies")
            transaction.enableVendor("twitter")
            transaction.commit()
          }
        },
      }}
      {...props}
    >
      {children}
      <DidomiSDK
        apiKey={process.env.DIDOMI_API_KEY}
        iabVersion={2}
        gdprAppliesGlobally={true}
        onReady={handleConsentChanged}
        onConsentChanged={handleConsentChanged}
        config={{
          theme: {
            css: `
              #didomi-host {
                  font-family: DINPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
              }

              #didomi-host a, #didomi-host p, #didomi-host span {
                text-rendering: initial !important;
                -webkit-font-smoothing: initial !important;
              }

              #didomi-host .didomi-notice-banner {
                background: #ffffff !important;
                color: #4F5F7A !important;
                font-family: DINPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
                box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
              }

              #didomi-host a:not(.didomi-no-link-style) {
                color: #2A508F !important;
                text-decoration: underline !important;
              }

              #didomi-host .didomi-notice-banner .didomi-notice-text {
                font-size: 16px !important;
                font-weight: normal !important;
                line-height: 1.5 !important;
                font-family: DINPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
               !important;
               letter-spacing: initial !important;
              }

              #didomi-host .didomi-exterior-border {
                border: none !important;
                padding: 0;
                border-radius: 0;
              }

              #didomi-host .didomi-popup-container {
                border: none !important;
                border-radius: 0;
              }

              #didomi-host a:not(.didomi-no-link-style):hover {
                color: #2A508F !important;
                text-decoration: underline !important;
              }

              #didomi-host .didomi-components-button {
                height: auto !important;
              }

              #didomi-host .didomi-buttons button.didomi-button {
                display: inline-block !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 1 !important;
                text-transform: uppercase !important;
                text-decoration: none !important;
                padding: 15px 30px !important;
                text-align: center !important;
                border-radius: 3px !important;
                outline: none !important;
                border: none !important;
                color: #FFF !important;
                background: #2A508F !important;
              }

              #didomi-host .didomi-notice-banner .didomi-buttons .didomi-button-standard {
                padding: 15px 30px !important;
                font-size: 12px !important;

              }

              #didomi-host .didomi-notice-banner .didomi-buttons .didomi-button-highlight {
                color: #FFF !important;
                font-size: 12px !important;
                padding: 15px 30px !important;
              }

              #didomi-host .didomi-notice-banner .didomi-buttons .didomi-learn-more-button {
                background: #FFFFFF !important;
                color: #2A508F !important;
                border-bottom:solid 1px #2A508F !important;
                text-decoration: underline;
                padding: 0 !important;
                min-width: auto !important;
                width: auto !important;
                border-radius: 0 !important;
                margin-right: 40px !important;
              }

              #didomi-host .didomi-notice-banner .didomi-buttons .didomi-disagree-button {
                background: #FFFFFF !important;
                color: #2A508F !important;
                border-bottom:solid 1px #2A508F !important;
                text-decoration: underline;
                padding: 0 !important;
                min-width: auto !important;
                width: auto !important;
                border-radius: 0 !important;
                margin-right: 40px !important;
              }

              #didomi-host .didomi-screen-xsmall .didomi-notice-banner .didomi-buttons .didomi-learn-more-button,
              #didomi-host .didomi-screen-xsmall .didomi-notice-banner .didomi-buttons .didomi-disagree-button {
                margin-right: 0 !important;
                margin-bottom: 20px !important;
              }

              #didomi-host .didomi-notice-banner .didomi-buttons .didomi-dismiss-button {
                background: #2A508F !important;
                color: #ffffff !important;
              }

              #didomi-host .didomi-popup-backdrop {
                background-color: rgba(79, 95, 122, 0.8) !important;
              }

              #didomi-host .didomi-popup-footer, #didomi-host .didomi-popup-footer .didomi-popup-actions {
                background-color: #EEF2F7 !important;
              }

              #didomi-host .didomi-consent-popup-preferences-vendors .didomi-consent-popup-container-click-all {
                background-color: #EEF2F7 !important;
              }

              #didomi-host .didomi-consent-popup-preferences-vendors .didomi-consent-popup-body_vendors-list {
                border: 2px solid #EEF2F7 !important;
              }

              #didomi-host .didomi-consent-popup-preferences-vendors .didomi-user-information-container {
                border: 2px solid #EEF2F7 !important;
              }

              .didomi-arrow-back-popup-preferences-vendors {
                color: #2A508F !important;
              }

              #didomi-host .didomi-consent-popup-preferences .didomi-consent-popup-data-processing, #didomi-host .didomi-consent-popup-preferences .didomi-consent-popup-vendor {
                margin-bottom: 15px !important;
              }

              #didomi-host .didomi-popup-footer {
                height: 70px !important;
              }

              #didomi-host .didomi-screen-xsmall .push-right, #didomi-host .didomi-screen-small .push-right {
                margin-right: 0px !important;
              }

              #didomi-host .didomi-mobile #didomi-notice.didomi-regular-notice.bottom, #didomi-host .didomi-mobile #didomi-notice.didomi-regular-notice.bottom .didomi-notice__interior-border {
                border: none !important;
              }

              #didomi-host .didomi-screen-xsmall #didomi-notice.didomi-regular-notice #buttons.multiple button {
                width: auto !important;
              }

              #didomi-host .didomi-mobile .didomi-consent-popup-preferences-purposes .didomi-consent-popup-footer {
                min-height: 70px !important;
              }

              #didomi-host .didomi-mobile .didomi-logo-icon {
                margin-right: 10px;
              }

              #didomi-host svg {
                display: inline-block;
              }
          `,
          },
        }}
      />
    </DidomiContext.Provider>
  )
}

export const useDidomi = () => useContext(DidomiContext)

export default DidomiContext

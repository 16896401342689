import React, { createContext, useContext, useState, useCallback } from "react"

const AccessibilityContext = createContext()

export const AccessibilityProvider = props => {
  const [accessibility, setAccessibility] = useState({ accessible: false })
  const setAccessible = useCallback(
    state => setAccessibility(prev => ({ ...prev, accessible: state })),
    [setAccessibility]
  )
  return (
    <AccessibilityContext.Provider
      value={{
        accessibility,
        setAccessibility,
        setAccessible,
      }}
      {...props}
    />
  )
}

export const useAccessibility = () => useContext(AccessibilityContext)

import React from "react"
import styled from "styled-components"
import cn from "classnames"

const UnstyledContainer = ({ className, fluid, ...props }) => (
  <div
    className={cn(fluid ? "px-3 w-full" : "container", className)}
    {...props}
  />
)

const Container = styled(UnstyledContainer)``

export default Container
